.footer {
  @include s {
    justify-content: space-between;
    display: flex;
    padding: 10vh 0;
    width: 100%;
  }

  a {
    color: white;
    text-decoration: none;
    font-size: rem(18px);
  }
}