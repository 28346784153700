body {
  background-color: $black;
  color: $white;
}

.container {
  width: 90vw;
  margin: auto;
}

ul {
  list-style-type: none;
  padding-left: 0;
}