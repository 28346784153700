// Function for converting a px based font-size to rem.
@function rem($size) {
  @return $size / 16px * 1rem;
 }
 
// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}
